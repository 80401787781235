import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import { EntryHeader, EntryTitle } from "../components/entry"

const EventCalendar = () => (
  <Layout>
    <SEO title="Event Calendar" />
    <Card variant="primary">
      <EncloseFloats>
        <EntryHeader>
          <EntryTitle>Event Calendar</EntryTitle>
        </EntryHeader>
        <p>
          Sorry, the Event Calendar page has been discontinued. Manually
          maintaining the event list is too difficult. This may be revisited in
          the future if enough conventions and groups publish their events in a
          format that can be scanned.
        </p>
      </EncloseFloats>
    </Card>
  </Layout>
)

export default EventCalendar
